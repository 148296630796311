@import "../color/lib";
@import "../util/lib";
@import "../type/lib";

.link {
  font-weight: 600;
  font-style: italic;
  border: 0;
  background: transparent;
  padding: 0;
  outline: 0;

  @at-root {
    a#{&}, button#{&} {
      color: inherit;

      @include hover {
        color: inherit;
        opacity: .65;
        text-decoration: none;
        outline: 0;
      }
    }
  }

  &--arrow {
    &:after {
      @include icon(long-arrow-alt-right);
      margin-left: .65em;
      margin-right: .2em;
      transition: transform .2s;
      transform: translate(0, 0);
    }

    &.btn {
      &:after {
        transition-delay: .18s;
      }
    }

    @include hover {
      &:after {
        transform: translate(.2em, 0);
      }
    }
  }

  &--arrow-left {
    &:before {
      @include icon(long-arrow-alt-left);
      margin-right: .65em;
    }
  }

  &--underline {
    position: relative;
    padding-bottom: pxtoem(8);
    display: inline-block;

    &:after {
      content: '';
      width: 100%;
      position: absolute;
      border-bottom: 1px solid;
      bottom: 0;
      left: 0;
      opacity: .5;
    }
  }
}
