@import "../media/lib";
@import "../util/lib";

.bar {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: calc(100% + 1.5rem);
    border-bottom: 1px solid;
    color: inherit;
    opacity: .25;
    width: $bar-width;
  }

  &--shifted {
    @include breakpoints {
      padding-left: calc(#{$bar-width} + 1.5rem);

      &.bar {
        &:before {
          right: auto;
          left: 0;
        }
      }
    }
  }

  &--animate {
    transition: all 1.2s cubic-bezier(.45,.23,.27,1);

    &:before {
      transition: all .8s;
      transform-origin: 0 0;
      transform: scaleX(0);
    }

    .text {
      display: block;
      transform: translateX(-1.5rem);
      opacity: 0;
      transition: all 1.4s ease-out;
    }

    &.active {
      &:before {
        transform: scaleX(1);
      }

      .text {
        transform: translateX(0);
        opacity: 1;
      }
    }

    @include loading {
      &:before {
        transform: scaleX(0);
      }

      .text {
        transform: translateX(-1.5rem);
        opacity: 0;
      }
    }
  }
}
