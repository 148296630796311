@import "../type/lib";
@import "../color/lib";
@import "../util/lib";
@import "../layout/lib";

/* video Modal */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90001;
  height: 100vh;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity .3s;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(33, 33, 33, .95);

  .container {
    max-width: 1600px;
  }
}

.modal.active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.modal .top {
  height: 3em;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  background: black;

  .angle {
    height: 100%;
    width: 27em;
    background: white;
    clip-path: polygon(0 0,0 100%,100% 100%,100% 100%,94% 0);
    @media(max-width:$md-max) {
      width: unset;
    }
  }
}

.modal .top a.close-modal {
  position: absolute;
  right: 30px;
  top: 30px;
  padding: 0;
  display: block;
  z-index: 50;
  height: 50px;
  width: 90px;
  text-align: right;
  padding-top: 10px;
  padding-right: 5px;
  color: #ffffff;
  font-weight: 300;
  font-size: 20px;
}

.close-modal {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: 0;
  background: transparent;
  z-index: 100000;
}

.close-modal:before, .close-modal:after {
  width: 35px;
  height: 4px;
  background-color: #fff;
  content: "";
  display: block;
  -webkit-transform: rotate( 45deg );
  transform: rotate( 45deg );
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: 0;
}

.close-modal:after {
  transform: rotate(-45deg);
}

.modal_content {
  top: 15vh;
  left: 50%;
  position: relative;
  transform: translate(-50%, 0);
  background: white;
  padding: 0 !important;

  .content {
    padding: 2em 4em 5em 4em;

    .background-cover {
      height: 26em;
      clip-path: polygon(0 0,0 100%,100% 100%,100% 20%,87% 0);
      background-position: top !important;
      width: 100%;
    }

    .text {
      padding-left: 4em;

      @media(max-width:$md-max) {
        margin-left: 0;
        padding: 2em 0 0 0;
      }

      .leader-name {
        font-size: 2em;
        font-weight: 600;
      }

      .italics {
        font-style: italic;
        font-size: 1.25em;
        font-weight: 300;
        margin-bottom: .5em;
      }

      p {
        font-size: 1em;
        padding-bottom: 1em;
        font-weight: 400;
        line-height: 1.6;
      }

      .modal-btn {
        cursor: pointer;
        border: 1px solid black;
        color: black;
        padding: .5em 1em;
        font-size: 0.85em;
        font-weight: 600;
      }
    }
  }
}

.open-modal, .open-vet-modal {
  font-size: .85em;
  color: #263b85;
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}


@media(max-width:991px) {
  .modal .top a.close-modal {
    width: 40px;
    top: 10px;
    right: 15px;
  }

  .close-modal:before, .close-modal:after {
    right: 0;
  }

  .modal_content {
    padding: 80px 0 0 0;
  }
}
