@import 'node_modules/sass-math-pow/sass/math-pow';
@import "../media/lib";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// Ratios
$major-second: 1.125;
$minor-third: 1.200;
$major-third: 1.25;
$perfect-fourth: 1.33333;
$augmented-fourth: 1.414;
$perfect-fifth: 1.5;

$type-ratio: $major-third !default;

$font-body: 'Roboto', sans-serif !default;
$font-headline: 'Roboto', sans-serif !default;

$base-font-size: 20px !default;

@function pxtoem($val, $context: $base-font-size) {
  @if (unitless($val)) {
    $val: $val * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $val / $context * 1em;
}

@function pxtorem($val) {
  @if (unitless($val)) {
    $val: $val * 1px;
  }

  @return $val / $base-font-size * 1rem;
}

@function make-ratio($val, $ratio: $type-ratio) {
  @return poly-pow($ratio, $val);
}

@mixin unresponsive-font-size($val, $ratio: $type-ratio) {
  font-size: make-ratio($val, $ratio) * 1em;
}

@mixin font-size($val) {
  font-size: make-ratio($val, $minor-third) * 1em;

  @media(min-width: $md) {
    font-size: make-ratio($val, $type-ratio) * 1em;
  }
}

@mixin uppercase-headline {
  font-weight: 900;
  text-transform: uppercase;
  font-family: $font-headline;
  line-height: 1.25;
}

@mixin lowercase-headline {
  font-weight: 700;
  text-transform: none;
  font-family: $font-headline;
  letter-spacing: 0em;
}

@mixin p {
  @include font-size(0);
  text-transform: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  font-family: inherit;
  line-height: inherit;
}

@mixin h1 {
  @include font-size(5);
  @include lowercase-headline;
  line-height: 1.15;
  margin: 0 0 .6em;
  font-weight:700!important;
}

@mixin h2 {
  @include font-size(6);
  @include lowercase-headline;
  line-height: 1.2;
  margin: 0 0 .75em;
}

@mixin h3 {
  @include font-size(3);
  @include lowercase-headline;
  line-height: 1.25;
  margin: 0 0 .65em;
}

@mixin h4 {
  @include font-size(2);
  @include lowercase-headline;
  line-height: 1.3;
  margin: 0 0 0.75em;
}

@mixin h5 {
  @include font-size(1);
  @include lowercase-headline;
  line-height: 1.35;
  margin: 0 0 1em;
}

@mixin h6 {
  @include font-size(-1);
  font-family: $font-body;
  letter-spacing: .25em;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0 0 2em;

  @media (min-width: $sm) {
    margin: 0 0 3em;
  }
}
