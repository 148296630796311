@import "../type/lib";
@import "../color/lib";
@import "../util/lib";
@import "../media/lib";
@import "../layout/lib";

#map {
    height:800px;
}

.map-popup {
  .map-popup_inner {
      font-size:1em;

    .category {
      color: #263B85;
      font-weight:700;
      font-size:14px;
      text-transform:uppercase;
      margin-bottom:20px;
    }

    h3 {
        font-size:20px;
        font-weight:700;
        margin-bottom:10px;
    }

    .address {
        font-size:18px;
        font-weight:300;
    }
  }
}

.map-key {
  padding: 2em 0;
  display: inline-block;
  width: 100%;

  h2 {
    border-bottom: 2px solid $light-gray;
    padding-bottom: 0.5em;
    font-weight: 600;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    column-gap: 0px;
    column-count: 6;

    li {
      display: block;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      color: $dark-blue;
      font-weight:600;
    }
  }

  @media(max-width:$lg-max) {
    ul {
      column-count: 3;
    }
  }

  @media(max-width:$xs-max) {
    ul {
      column-count: 2;
    }
  }
}
