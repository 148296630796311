@import "../type/lib";
@import "../color/lib";
@import "../util/lib";
@import "../layout/lib";


.careers-veterans {
  background-color: #303030;
  position: relative;

  .background-cover {
    height: 100%;
    position: absolute;
    width: 100%;
    opacity: 1;
    z-index: 1;
    mix-blend-mode: overlay;
  }

  .col-md-6 {
    > div {
      padding: 5em;

      @media(max-width:$md-max) {
        padding: 2em;
      }
    }

    padding {
      padding-bottom: 0.5em;
    }
  }

  .btn {
    border: none;
    color: $black!important;
    background: $blue;

    &:hover {
      background: white;
      color: black !important;
    }
  }
}

.news-callout {
  .d-flex {
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px #707070 solid;

    a {
      color: black !important;
      font-size: .85em;
      font-weight: 600;
    }
  }

  .home-news {
    padding-top: 1em;


    .d-flex {
      padding: 1em 0;
      border: 0;
      color: black !important;
      align-items: initial;
      cursor: pointer;

      .home-news-text {
        width: 62em;
      }

      .background-cover {
        width: 24em;
        height: 7em;
        margin-right: 1.5em;
      }

      .home-date {
        font-size: .7em;
      }

      .hn-title {
        font-size: 1em;
        font-weight: 400;
        line-height: 1.6em;
      }
    }
  }
}

.home-investor {
  .investor-price {
    justify-content: unset;
    border: 0;
    position: relative;

    .price {
      font-size: 4.6em;
      font-weight: 600;
      color: black;
    }

    .dollar {
      font-size: 2.5em;
      color: black;
      position: relative;
      top: -.5em;
    }
  }

  .qual-name {
    font-size: 1.2em;
  }

  .investor-table {
    font-size: 1.2em;

    .d-flex {
      padding: .7em 0;
    }

    .d-flex:last-child {
      border: 0;
    }

    .table-price {
      font-size: 1em;
    }
  }
}


  .contact-content {
    h2 {
      font-size: 1.5em;
      font-weight: 400;
      margin-bottom: 1em;
    }
  }

.careers-content {
  h4 {
    font-size: 1.25em;
  }

  h3 {
    color: $dark-blue;
    font-size: 1.25em;
    font-weight: 600;
    margin-top: 0.6em;
  }

  .d-md-flex {
    justify-content: space-between;

    > div {
      width: 46%;

      @media(max-width: $md-max) {
        width: 100%;
      }
    }

    p {
      font-size: 1em;
      font-weight: 400;
      margin-bottom: 1.5em;
      line-height: 1.6em;
    }
  }
}

.contact-box {
  padding: 1.5em;

  h3 {
    font-size: 1.2em;
    font-weight: 600
  }

  a {
    color: white;
  }

  .btn {
    background-color: transparent;
    border: 1px solid white;

    &:hover {
      border: 1px solid $blue;
      color: black;
      background-color: $blue;
    }
  }

  @media (max-width:$md-max) {
    margin: 26px;
    margin-bottom: 42px;
  }
}

  .home {
    .btn:first-child {
      text-transform: uppercase;
      color: white;
    }
  }

  .contact-banner {
    .contact-content {
      @media(max-width: $sm-max) {
        padding-left: inherit;
      }
    }
  }

.landing {
  #main-content {
    background-image: url('/areas/cms/assets/img/landing-bg.png');
    background-size: initial;
    background-position: center 19em;
  }

  /*.quotation {
    padding-bottom:0!important;
  }*/
  .gallery {
    padding-top: 0!important;
  }
}

.experience {
  h3 {
    margin: 0;
    padding-bottom: .6em;
    font-size: 1.8em;
    font-weight: 600;
    border-bottom: 1px solid #707070;
  }

  .row {
    padding-top: 1em;
  }

  ul {
    column-count: 2;
    column-gap: 40px;

    li {
      break-inside: avoid;
      font-weight: 400;
    }
  }
}

.inquiries {
  div {
    font-size: 1em;
    font-weight: 400;
  }

  h4 {
    font-size: 1.25em;
    font-weight: 600;
    padding-top:1.5em;
  }

  a {
    color: white!important
  }
}

.home-vet {
  position: relative;
  background-color: #263B85;


  .background-cover {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: .4;
    z-index: 1;
    mix-blend-mode: hard-light;
  }

  .row {
    position: relative;
    z-index: 2;
    padding: 5em 0;
    align-items: center;

    &:after {
      content: "";
      position: absolute;
      background-color: #051B44;
      height: 25.75em;
      opacity: .6;
      -webkit-transform: skewX( 30deg );
      transform: skewX( 30deg );
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      left: -45%;
    }


    > div {
      color: #fff;
      position: relative;
      z-index: 2;

      img {
        float: right;
        max-width: 100%;
      }

      h2 {
        line-height: 1;
        padding-bottom: .2em;
      }

      p {
        font-size: 1em;
        line-height: 1.6;
        font-weight: 400;
        margin-bottom: 1.25em;
      }
    }
  }

  .btn {
    background-color: $blue;
    color: #051B44 !important;

    &:hover {
      background-color: white;
    }
  }

  @media(max-width:$md-max) {
    .row {
      &:after {
        transform: none;
        left: -10em;
        width: 150%;
      }

      .img-div {
        text-align: center;

        img {
          float: unset;
          padding-top: 2em;
        }
      }
    }
  }
}

#service-tabs {
  margin-bottom: 1.5em;
  outline: none;
}
