@import "../media/lib";
@import "../color/lib";
@import "../type/lib";
@import "../util/lib";
@import "../layout/lib";

.banner {
  position: relative;
  z-index: 2;

  &--home {
    padding-bottom: 5em;
    color: white;
    background: black;

    .home-video {
      position: relative;
      z-index: 2;
      height: 57em;

      video {
        position: absolute;
        min-width: 100%;
        right: 50%;
        transform: translateX(50%);
      }

      &:after {
        content: "";
        position: absolute;
        background-color: black;
        height: 25.75em;
        width: 65%;
        opacity: .7;
        transform: skewX(30deg);
        left: -9em;
        top: 0;
        height: 100%;
        z-index: 1;
        border-right: 1px solid #E5E5E5;
      }

      &:before {
        content: "";
        position: absolute;
        background-color: black;
        height: 25.75em;
        width: 45%;
        right: -20em;
        opacity: .7;
        transform: skewX(30deg);
        top: 0;
        height: 100%;
        z-index: 1;
        border-left: 1px solid #E5E5E5;
      }

      @media(max-width: $lg-max) {
        &:before {
          width: 35%;
        }

        &:after {
          width: 92%;
        }
      }

      @media(max-width: $sm) {
        height: 40em;
        overflow: hidden;
        margin-left: -1px;

        &:before {
          width: 100%;
          transform: unset;
          left: 0;
        }

        &:after {
          width: 0;
          transform: unset;
          left: 0;
        }
      }

      @media(max-width:$xs-max) {
        height: 35em;
      }
    }

    .home {
      padding-top: 15em;
      padding-bottom: 20em;
      position: absolute;
      top: 0;
      width: 100%;

      @media(max-width: $sm) {
        padding-top: 12em;
        padding-bottom: 10em;
      }

      @media(max-width:$xs-max) {
        padding-top: 8em;
        padding-bottom: 7em;
      }

      p {
        font-size: 1.25em;
        line-height: 1.6;
        max-width: 26em;
        margin-bottom: 1.25em;
        font-weight: 400;
      }
    }

    h1 {
      font-size: 5.5em;
      margin-bottom: .5rem !important;
      line-height: 1;
      max-width: 6em;

      @media(max-width:$xs-max) {
        font-size: 3.5em;
      }
    }

    &:after {
      content: '';
      height: 63em;
      width: 100%;
      position: absolute;
      top: 0;
      background-image: linear-gradient(0deg,#000 30%,rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0),rgba(0, 0, 0, 0),transparent);
      z-index: 2;

      @media(max-width:$xs-max) {
        height: 53em;
      }
    }
  }

  &--landing {
    position: relative;
    z-index: 2;
    margin-top: 60px;

    h1 {
      padding: 2em 0;
      font-size: 5em;
      position: relative;
      z-index: 2;
      margin: 0;

      @media(max-width:$sm-max) {
        font-size: 10vw;
      }
    }

    &:before {
      content: "";
      position: absolute;
      background-color: black;
      height: 100%;
      width: 60%;
      opacity: .4;
      transform: skewX(30deg);
      left: -9em;
    }

    &:after {
      content: "";
      position: absolute;
      background-color: black;
      height: 100%;
      width: 80%;
      opacity: .3;
      transform: skewX(30deg);
      left: -9em;
      top: 0;
    }

    &.int {
      .container {
        padding-top: 9em;
        padding-bottom: 9em;

        h1 {
          padding: 0;
        }
      }

      .bread {
        color: white;

        a {
          color: white;
        }
      }
    }

    @media(max-width: $md-max) {
      &:before {
        width: 85%;
      }

      &:after {
        width: 105%;
      }
    }

    @media(max-width: $sm) {
      &:before {
        width: 100%;
        transform: unset;
        left: 0;
      }

      &:after {
        width: 100%;
        transform: unset;
        left: 0;
      }
    }
  }

  &--landing-vid {
    position: relative;
    z-index: 2;
    margin-top: 60px;

    .section-title_services {
      padding: 17em 0;
      color: white;

      h1 {
        padding: 0;
        font-size: 5em;
        position: relative;
        z-index: 2;
        margin: 0;

        @media(max-width:$xxs-max) {
          font-size: 3.6em;
        }
      }

      @media(max-width:$lg-max) {
        padding: 13.7vw 0;
      }

      .bread {
        a {
          color: white;
        }
      }
    }

    &:before {
      content: "";
      position: absolute;
      background-color: black;
      height: 100%;
      width: 75%;
      opacity: .4;
      transform: skewX(30deg);
      left: -12em;
      z-index: 1
    }

    &:after {
      content: "";
      position: absolute;
      background-color: black;
      height: 100%;
      width: 95%;
      opacity: .3;
      transform: skewX(30deg);
      left: -12em;
      top: 0;
    }

    @media(max-width:$md-max) {
      &:before {
        width: 86%
      }

      &:after {
        width: 106%
      }
    }

    @media(max-width:$md-max) {
      &:before {
        transform: unset;
        width: 100%;
        left: 0;
      }

      &:after {
        transform: unset;
        width: 100%;
        left: 0;
      }
    }

    .video-div {
      overflow: hidden;
      height: 100%;
      position: absolute;
      top: 0;
      max-height: unset !important;
      width: 100%;
      background-color: #5594ff;

      .banner-vid {
        top: 5em;

        @media(max-width: 1299px) {
          top: 0;
          transform: none;
        }
      }
    }
  }

  &--interior {
    margin-top: 100px;

    .container {
      padding-top: 2em;
      padding-bottom: 2em;
    }

    h1 {
      margin: 0;
      font-size: 5em;

      @media(max-width: $xs-max) {
        font-size: 3.2em;
      }
    }

    .image {
      height: 20em;
    }
  }
}

  .landing-bg {
    &:after {
      content: '/areas/cms/assets/img/landing-bg.png';
      z-index: 1;
      /*background-image: url();*/
      top: 10em;
      left: -31em;
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }

  .content {
    position: relative;
    z-index: 2;
  }


  .video-div {
    overflow: hidden;
    height: 47vw;
    max-height: 32em;

    .banner-vid {
      position: relative;
      top: 0;
      transform: translateY(-18%);
      width: 100%;
    }
  }


  .section-title {
    margin: 0;
    font-size: 5em;
    text-transform: none;
    font-family: Roboto,Arial,sans-serif;
    letter-spacing: -.02em;
    line-height: 1.15;
    font-weight: 700 !important;
  }


  .section-title_services {
    h1 {
      display: inline-block;
    }

    img {
      vertical-align: top;
      padding-top: 1em;
      padding-left: 1em;
      width: 50px;

      @media(max-width:991px) {
        padding-left: 0;
        padding-bottom: 1em;
      }
    }
  }
