@import "lib";
@import "../util/lib";

.bg {
  @include colors {
    background-color: $color!important;
  }

  &-stripes {
    background-color: $off-white;
  }
}

.text {
  @include colors {
    color: $color!important;
  }
}

.hover {
  @include colors {
    @include hover {
      color: $color;
    }
  }
}

.gray-content {
  p, ul, .h6 {
    color: $medium-gray;
  }
}
