@import "../type/lib";
@import "../color/lib";
@import "../util/lib";
@import "../layout/lib";

* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.job {
  display: block;
  margin-bottom: 2em;
  padding: 2em 3em;
  background: #E8E8E8;
  text-decoration: none;
  cursor: pointer;
  color: black !important;
  clip-path: polygon(0 0,0 100%,100% 100%,100% 45%,95% 0);

  .row {
    align-items: flex-start;

    .col-md-3 {
      text-align: center;

      .btn {
        background-color: #000;
        color: #fff;
        font-size: .8em;
        padding: 1em;

        &:hover {
          color: black!important;
        }

        @media(min-width:$lg) {
          margin: 0 4em;
        }
      }
    }
  }
}

.job:hover {
  background: #ddd;
}

.job:hover p {
  color: #454545;
}

h2 {
  margin: 0;
}

.job-title {
  font-size: 1.6em !important;
  text-decoration: underline;
  color: #263B85;
}

h2.team-title {
  font-size: 2em;
  text-decoration: none;
  color: #000000;
  border-bottom: 2px solid #dddddd;
  padding-top: 1em;
  font-weight: 600;
  padding-bottom:.5em;
}

.tags {
  margin: 0 !important;
      text-transform: uppercase;

  span {
    color: black;
    font-size: 0.7em;
    font-weight: 700;
  }
}

.description {
  color: black;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.6;
}

#lever-clear-filters {
  cursor: pointer;
  background: $blue;
  color: white;

  &:hover {
    color: black!important;
  }
}

#lever-jobs-filter {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
  width: 100%;

  .filter-by {
    font-size: 1.5em;
    font-weight: 600;
    white-space: nowrap;
  }

  select {
    border: 3px solid black;
    padding: .5em;
    margin-right: 15px;
    width: 100%;
  }


  .d-flex {
    align-items: baseline;
    /*width: 20%;*/
    text-align: right;

    #lever-jobs-filter-fulltime {
      width: 2em;
    }

    label {
      font-size: 1em;
      font-weight: 300;
      white-space: nowrap;
      text-align: left;
    }
  }
}

.lj-location {
  @media(max-width:$xs-max) {
    margin-bottom: 1em;
  }
}
