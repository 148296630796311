@import "../media/lib";
@import "../color/lib";
@import "lib";

@mixin padding-tblr($value) {
  &-top {
    padding-top: $value;
  }

  &-bottom {
    padding-bottom: $value;
  }

  &-left {
    padding-left: $value;
  }

  &-right {
    padding-right: $value;
  }
}

@mixin margin-tblr($value) {
  &-top {
    margin-top: $value;
  }

  &-bottom {
    margin-bottom: $value;
  }

  &-left {
    margin-left: $value;
  }

  &-right {
    margin-right: $value;
  }
}

.block {
  @include breakpoints {
    @include blocks {
      padding: $block-size;
    }

    @include blocks {
      @include padding-tblr($block-size);
    }

    &-margin {
      @include blocks {
        margin: $block-size;
      }

      @include blocks {
        @include margin-tblr($block-size);
      }
    }
  }
}

.banner {
  @include breakpoints {
    @include blocks {
      @extend .block#{$bp-suffix}#{$block-suffix}-top;
      @extend .block#{$bp-suffix}#{$block-suffix}-bottom;

      &-top {
        @extend .block#{$bp-suffix}#{$block-suffix}-top;
      }

      &-bottom {
        @extend .block#{$bp-suffix}#{$block-suffix}-bottom;
      }
    }

    &-margin {
      @include blocks {
        @extend .block#{$bp-suffix}-margin#{$block-suffix}-top;
        @extend .block#{$bp-suffix}-margin#{$block-suffix}-bottom;

        &-top {
          @extend .block#{$bp-suffix}-margin#{$block-suffix}-top;
        }

        &-bottom {
          @extend .block#{$bp-suffix}-margin#{$block-suffix}-bottom;
        }
      }
    }
  }
}


.block-responsive {
  @extend .block-xl;
  @extend .block-md-medium;
  @extend .block-xs-small;
  @extend .block-gutter;

  @media(max-width: $xxs-max) {
    padding: 15px;
  }

  &-top {
    @extend .block-xl-top;
    @extend .block-md-medium-top;
    @extend .block-xs-small-top;
    @extend .block-gutter-top;

    @media(max-width: $xxs-max) {
      padding-top: 15px;
    }
  }

  &-bottom {
    @extend .block-xl-bottom;
    @extend .block-md-medium-bottom;
    @extend .block-xs-small-bottom;
    @extend .block-gutter-bottom;

    @media(max-width: $xxs-max) {
      padding-bottom: 15px;
    }
  }

  &-left {
    @extend .block-xl-left;
    @extend .block-md-medium-left;
    @extend .block-xs-small-left;
    @extend .block-gutter-left;

    @media(max-width: $xxs-max) {
      padding-left: 15px;
    }
  }

  &-right {
    @extend .block-xl-right;
    @extend .block-md-medium-right;
    @extend .block-xs-small-right;
    @extend .block-gutter-right;

    @media(max-width: $xxs-max) {
      padding-right: 15px;
    }
  }
}

.banner-responsive {
  @extend .block-xl-margin-large-top;
  @extend .block-xl-margin-large-bottom;
  @extend .block-md-margin-top;
  @extend .block-md-margin-bottom;
  @extend .block-margin-medium-top;
  @extend .block-margin-medium-bottom;

  &-top {
    @extend .block-xl-margin-large-top;
    @extend .block-md-margin-top;
    @extend .block-margin-medium-top;
  }

  &-bottom {
    @extend .block-xl-margin-large-bottom;
    @extend .block-md-margin-bottom;
    @extend .block-margin-medium-bottom;
  }
}


.banner-padding-responsive {
  @extend .banner-xl-large;
  @extend .banner-sm;
  @extend .banner-small;

  &-top {
    @extend .banner-xl-large-top;
    @extend .banner-sm-top;
    @extend .banner-small-top;
  }

  &-bottom {
    @extend .banner-xl-large-bottom;
    @extend .banner-sm-bottom;
    @extend .banner-small-bottom;
  }
}
