@import "lib";
@import "../color/lib";
@import "../media/lib";
@import "../layout/lib";

.fixed-top {
  right: unset;
  width: 100%;
}

.clearfix {
  @include clearfix();
}

.absolute-center {
  @include absolute-center();
}

.margin-center {
  @include margin-center();
}

.overflow-hidden {
  overflow: hidden;
}

.relative {
  position: relative;
}

.pipe {
  outline: 1px solid;
  box-sizing: content-box;
}

.background-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.h {
  @include breakpoints {
    &-100 {
      height: 100%;
    }
  }
}

.w {
  &-100 {
    width: 100%;
  }
}

.z-higher {
  position: relative;
  z-index: 50;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.hidden {
  visibility: hidden;
}

.square {
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

.caret {
  @include caret-down(pxtoem(67), pxtoem(84));
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  z-index: 50;

  @media(max-width: $sm-max) {
    font-size: .75em;
  }
}

.img-responsive {
  display: block;
  max-width: 100%;
}

.shadow {
  box-shadow: 0 0 0.75em 0.125em rgba(0, 0, 0, .5);

  &--bottom {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 1.5%;
      position: absolute;
      bottom: -3em;
      left: 0;
      background: rgba($black, 0.125);
      border-radius: 100%;
      filter: blur(0.5em);
    }
  }
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  > video, > img, > span, > figure {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    position: relative;
  }
}

.border-top {
  padding-top: 2em;

  @media (min-width: $md) {
    padding-top: $block-small;
  }

  border-top: 1px solid $black;
}

.anchor {
  margin-top: pxtoem(-50);
  position: absolute;
}

.clients-list-row {
  > div {
    &:nth-last-of-type(-n+2) {
      @media (max-width:$md-max) {
        margin-top: 26px;
      }
    }

    &:nth-child(2) {
      @media (max-width:$xs-max) {
        margin-top: 26px;
      }
    }
  }

  .clients-list {
    padding-top: 10px;

    @media (min-width:$xs) {
      padding-top: 15px;
    }

    @media (min-width:$md) {
      padding-top: 26px;
    }

    li {
      padding: pxtoem(2) 0;
    }

    @media(min-width: $xs) {
      margin-right: (1/2) / 2 * -100%;
    }

    @media(min-width: $md) {
      margin-right: (1/3) / 2 * -100%;

      li {
        padding: pxtoem(7) 0;
      }
    }

    @media(min-width: $lg) {
      margin-right: (1/4) / 2 * -100%;

      li {
        padding: pxtoem(10) 0;
      }
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.communicate-beautifully {
  font-size: 25px;
  margin-bottom: 1.5em;

  > span {
    opacity: 0.25;
    transition: opacity .6s;

    &.active {
      opacity: 1;
    }
  }

  @media (min-width: $sm) {
    font-size: 30px;
  }

  @media (min-width: $md) {
    font-size: 40px;
  }

  @media (min-width: $lg) {
    font-size: 38px;
  }

  @media (min-width: $xl) {
    font-size: 50px;
  }
}

.IEA-logo, .Distinguished-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: pxtovw(350);
  padding: 25%;

  > svg {
    width: 100%;
    height: auto;
  }
}

.Distinguished-logo {
  padding: 12% 30%;
  min-height: pxtovw(200);
}

.IEA-image {
  display: block;
  width: 100%;
}

.wide-image {
  margin-left: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 80%;

  @media (min-width: $sm) {
    padding-bottom: 40%;
  }

  @each $key, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint) {
      $side-bar-width-at-container: getSideBarWidthAtBreakpoint($breakpoint);

      $width: $breakpoint - ($gutter * 4) - $side-bar-width-at-container;

      width: calc(#{$width} + ((100% - #{$width}) / 2) + #{$bar-width} + 1.5rem);
    }
  }
}
