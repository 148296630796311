@import "../media/lib";
@import "../type/lib";
@import "lib";

$font-body: $font-body;
$font-family-sans-serif: $font-headline;
$font-family-base: $font-family-sans-serif !default;

$grid-breakpoints: map-merge(("xxs": 0px), $breakpoints);
$grid-gutter-width: $gutter * 2;

$container-max-widths: ();

@each $key, $breakpoint in $breakpoints {
  $side-bar-width-at-container: getSideBarWidthAtBreakpoint($breakpoint);

  @if $breakpoint > 568px {
    $container-max-widths: map-merge($container-max-widths, ($key: $breakpoint - $grid-gutter-width - $side-bar-width-at-container));
  }
}

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Optional
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/print";
/*@import "node_modules/bootstrap/scss/dropdown";*/
//@import "node_modules/bootstrap/scss/nav";
//@import "node_modules/bootstrap/scss/navbar";
//@import "node_modules/bootstrap/scss/transitions";
// @import "node_modules/bootstrap/scss/utilities";

.container {
  @include breakpoints {
    $side-bar-width-at-container: getSideBarWidthAtBreakpoint($bp);
    max-width: $bp - $grid-gutter-width - $side-bar-width-at-container;
  }
}

.container, .container-fluid {
  padding-left: $gutter;
  padding-right: $gutter;

  @media (max-width: 413px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.column-margin {
  margin-top: $grid-gutter-width / 2;
  margin-bottom: $grid-gutter-width / 2;
}

.wide-gutters {
  @include breakpoints {
    margin-right: -1 * $grid-gutter-width;
    margin-left: -1 * $grid-gutter-width;

    > .col,
    > [class*="col-"] {
      padding-right: $grid-gutter-width;
      padding-left: $grid-gutter-width;
    }

    > .column-margin {
      margin-top: $grid-gutter-width;
      margin-bottom: $grid-gutter-width;
    }
  }
}

.narrow-gutters {
  @include breakpoints {
    margin-right: -1 * $grid-gutter-width / 4;
    margin-left: -1 * $grid-gutter-width / 4;

    > .col,
    > [class*="col-"] {
      padding-right: $grid-gutter-width / 4;
      padding-left: $grid-gutter-width / 4;
    }

    > .column-margin {
      margin-top: $grid-gutter-width / 4;
      margin-bottom: $grid-gutter-width / 4;
    }
  }
}

.form-row {
  margin-left: -0.5em;
  margin-right: -0.5em;

  > .col,
  > [class*="col-"] {
    padding-right: 0.5em;
    padding-left: 0.5em;
  }

  > .column-margin {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
}

// Unused for now. We might want these later
//@import "node_modules/bootstrap/scss/type";
//@import "node_modules/bootstrap/scss/images";
//@import "node_modules/bootstrap/scss/code";
//@import "node_modules/bootstrap/scss/tables";
//@import "node_modules/bootstrap/scss/forms";
//@import "node_modules/bootstrap/scss/buttons";
//@import "node_modules/bootstrap/scss/button-group";
//@import "node_modules/bootstrap/scss/input-group";
//@import "node_modules/bootstrap/scss/custom-forms";
//@import "node_modules/bootstrap/scss/card";
//@import "node_modules/bootstrap/scss/breadcrumb";
//@import "node_modules/bootstrap/scss/pagination";
//@import "node_modules/bootstrap/scss/badge";
//@import "node_modules/bootstrap/scss/jumbotron";
//@import "node_modules/bootstrap/scss/alert";
//@import "node_modules/bootstrap/scss/progress";
//@import "node_modules/bootstrap/scss/media";
//@import "node_modules/bootstrap/scss/list-group";
//@import "node_modules/bootstrap/scss/close";
//@import "node_modules/bootstrap/scss/modal";
//@import "node_modules/bootstrap/scss/tooltip";
//@import "node_modules/bootstrap/scss/popover";
//@import "node_modules/bootstrap/scss/carousel";

