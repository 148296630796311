@import "../type/lib";
@import "../color/lib";
@import "../util/lib";
@import "../media/lib";
@import "../layout/lib";

#footer {
  font-size: .9em;
  font-weight: 300;
  background-color: #000000;
  position: relative;

  .container-fluid {
    padding-left: 0;
    padding-right: 0;

    .footer-left {
      background-color: #000000;
      padding: 7em 7.5em;
      color: #ffffff;
      font-weight: 300;

      @media(max-width:767px) {
        text-align: center;
      }

      .footer-heading {
        margin-bottom: 1.5em;
        line-height: 1.5em;


        a {
          color: #ffffff;
          font-weight: 600;
          font-size: 1.1em;
        }
      }

      .footer-address {
        margin-bottom: 1.5em;
      }

      ul.footer-social {
        list-style: none;
        margin: 0 0 1.5em 0;
        padding: 0;

        li {
          display: inline-block;

          a {
            color: #ffffff;
            font-size: 1.25em;
            margin-right: 0.75em;

            &:hover {
              color: $blue;
              text-decoration: none;
            }
          }
        }
      }

      ul.footer-nav {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
          margin-right: 1em;

          @media(max-width:767px) {
            margin-right: 0.5em;
            margin-left: 0.5em;
          }

          a {
            color: #ffffff;
            font-weight: 300;
            font-size: 1em;
          }
        }
      }

      .footer-menu {
        display: flex;
        justify-content: space-between;

        @media(max-width:767px) {
          flex-wrap: wrap;
        }

        @media(max-width:399px) {
          justify-content: center;
        }

        &.desktop {

          @media(max-width:767px) {
            display: none;
          }
        }

        &.mobile {
          display: none;

          @media(max-width:767px) {
            display: flex;
          }
        }

        .footer-menu_column {

          .footer-menu_heading {
            margin: 0 0 1.5em 0;
            padding: 0;
            line-height: 1.5em;
            min-width: 60px;


            @media(max-width:767px) {
              min-width: initial;
              padding: 0 1em;
            }

            a {
              color: #ffffff;
              font-weight: 600;
            }
          }

          ul {
            list-style: none;
            margin: 0;
            padding: 0;

            @media(max-width:767px) {
              display: none;
            }

            li {
              margin: 0 0 .9em 0;
              padding: 0;

              a {
                color: #ffffff;
                font-weight: 300;
              }
            }
          }
        }
      }

      .copyright {
        margin-bottom: 1.5em;
      }
    }

    .footer-contact {
      height: 100%;


      a {
        display: block;
        height: 100%;
        width: 100%;
        padding: 7em 3.5em;

        span {
          color: #000000;
          font-weight: 600;
          margin-right: 1em;
          font-size: 1.5em;
          vertical-align: middle;
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba(84,86,90,0);
          transition: all .25s;
        }

        &:hover {
          text-decoration: none;
        }

        &:hover:before {
          background-color: rgba(84,86,90,.25);
        }
      }
    }
  }
}

.footer-img {
  background-position-y:bottom;
}

