@import "../media/lib";

.moncur-bug {
  position: absolute;
  display:block;
  bottom:4em;
  left:7.5em;
  width: 12px;

  @media(max-width:767px) {
      left:50%;
      transform:translateX(-50%);
  }

  &:before {
    content: "Website by Moncur";
    font-size: 13px;
    font-family: Gill Sans, Verdana, sans-serif;
    position: absolute;
    left: calc(100% + 12px);
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    width: max-content;
    opacity: 0;
    transition: all 0.15s;
    pointer-events: none;
    color:#fff;

    @media (max-width:$sm-max) {
      display: none;
    }
  }

  img {
    opacity: .35;
    transition: all 0.15s;
  }

  &:hover, &:focus, &:active {

    &:before {
      opacity: 1;
    }

    img {
      opacity: 1;
    }
  }
}
