@import "../type/lib";
@import "../color/lib";
@import "../util/lib";

input, .file-input {
  padding: .5em 0;
  background: $white;
  border: 0;
  border-radius: 0;
  display: block;
  width: 100%;
  border-bottom: 2px solid black;
  font-weight: 300;
}

textarea {
  padding: .5em;
  background: $white;
  border: 0;
  border-radius: 0;
  display: block;
  width: 100%;
  border: 2px solid black;
  font-weight: 300;
}

label {
  display: block;
  margin: 0;
  width: 100%;
  position: relative;
  font-weight: 300;
  margin-bottom: .5em;
}

button {
  cursor: pointer;
}

.select {
  display: block;
  position: relative;

  select {
    font-weight: 300;
    padding: .5em 1em;
    appearance: none;
    padding-right: pxtoem(40);
    width: 100%;
    border: 2px solid black;

    @include hover {
      outline: 0;
    }

    option {
      font-weight: 300;
      font-size: 1em;
    }

    &::-ms-expand {
      display: none;
    }
  }

  &:after {
    content: url(/areas/cms/assets/img/select-arrow.png);
    font-size: 1em;
    position: absolute;
    right: 3em;
    top: 0;
    transform: translate(50%,35%);
    pointer-events: none;
  }
}

.form-group {
  color: inherit;

  input {
    text-transform: none;
    font-weight: 300;
    color: black;
  }
}

.form-group {
  color: inherit;
  margin-bottom: pxtoem(30);
}

::placeholder { 
  color: black;
  opacity: 1; 
}

:-ms-input-placeholder { 
  color: black;
}

::-ms-input-placeholder { 
  color: black;
}

.button-group {
  position: relative;
  display: flex;

  label {
    flex: 1;
  }

  button {
    flex: 0 0 auto;
    border: 0;
    background: transparent;
  }
}

.btn-search {
  border: none;
  background-color: $white;
  padding: 0 20px;
  border: solid 1px $light-gray;
  border-left: none;
}

.search-group {
  border: 1px solid $gray;
  background: $white;
  margin-bottom: pxtoem(30);

  input, button {
    background: transparent;
    border: 0;
  }
}

.veterans-form, .mergers-form {
  h3 {
    font-size: 2em;
    font-weight: 700;
  }

  .sub-head {
    margin-bottom: 1em;
    font-size: 1.25em;
    line-height: 1.5;
    font-weight: 400;
  }
}

.mergers-form {

  .checkbox-column {
    margin: 0 -10px;

    .d-flex {
      align-items: baseline;

      &.form-group {
        padding: 0 10px;
      }

      input[type=checkbox] {
        width: 1em;
        margin-right: 0.25em;
      }
    }
  }
}

.veterans-form, .mergers-form, .contact-form, .honor-form {
  .btn {
    text-transform: uppercase;
    font-size: .8em;
    border: 0;
    font-weight: 600;
    padding: .5em 2em;
  }
}

.text-right {
  text-align: right;
}

.anchor-destination {
  padding-top: 166px;
  margin-top: -166px;
}

@media (min-width: 568px){
    .anchor-destination {
      padding-top: 120px;
      margin-top: -120px;
  }
}

@media (min-width: 992px) {
  .anchor-destination {
    padding-top: 123px;
    margin-top: -123px;
  }
}

@media (min-width: 1280px) {
    .anchor-destination {
      padding-top: 143px;
      margin-top: -143px;
  }
}

.veterans-form {
  .row {
    padding-top: 1.5em;
  }
}

p.google-terms {
    margin-top:2em;
    margin-bottom:0;

    a {
        color: #263b85;
        text-decoration: underline;
        font-weight: 600;
    }
}
