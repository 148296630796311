@import "lib";
@import "../media/lib";
@import "../color/lib";
@import "../util/lib";


html,
body {
  width: 100%;
  overflow-x: hidden;
  min-width: 320px;
  font-family: $font-body;
  line-height: 1.7;
  color: $black!important;
  font-size: 15px;

  @media(min-width: $xs) {
    font-size: 16px;
  }

  @media(min-width: $xl) {
    font-size: $base-font-size;
  }
}

html {
  overflow-y: scroll;
}

#site {
  transition: opacity .5s .2s;

  @include loading {
    opacity: 0;
  }

  @include loaded {
    opacity: 1;
  }
}

#main-content {
  max-width: 3840px;
  margin: 0 auto;
  overflow: hidden;
}


p, .p {
  @include p;
  line-height: 1.6em;
  margin-bottom: 1.25em;
  font-weight: 400;
}

h1,
.h1 {
  @include h1;
}

h2,
.h2 {
  @include h2;
}

h3,
.h3 {
  @include h3;
}

h4,
.h4 {
  @include h4;
}

h5,
.h5 {
  @include h5;
}

h6,
.h6 {
  @include h6;
}

.uppercase-headline {
  @include uppercase-headline;
}

.lowercase-headline {
  @include lowercase-headline;
}

a {
  text-decoration: none;
  transition: all 0.15s;

  @include hover {
    text-decoration: underline;
  }
}

button {
  transition: all 0.15s;
}

.blank {
  color: inherit;

  @include hover {
    color: inherit;
  }
}

button.blank {
  padding: 0;
  border: 0;
  background: transparent;
  outline: 0;
}

%list-inline {
  padding-left: 0;
  margin-left: -1 * pxtoem(5);
  margin-right: -1 * pxtoem(5);
  list-style: none;

  > li {
    display: inline-block;
    padding-right: pxtoem(5);
    padding-left: pxtoem(5);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.list-inline {
  @extend %list-inline;
}

.pipe-list {
  @extend %list-inline;

  > li {
    &:after {
      content: " | ";
      display: inline-block;
      margin-left: pxtoem(10);
    }

    &:last-child:after {
      content: normal;
    }
  }
}

.pipe-list--tall {
  @extend %list-inline;
  margin-left: -1.5em;
  margin-right: -1.5em;

  > li {
    padding: .5em 1.5em;
    border-left: 1px solid;

    &:first-child {
      border: 0;
    }
  }
}

.slash-list {
  @extend %list-inline;
  margin-left: -1 * pxtoem(3);
  margin-right: -1 * pxtoem(3);

  > li {
    padding-right: pxtoem(3);
    padding-left: pxtoem(3);

    &:after {
      content: " / ";
      display: inline-block;
      margin-left: pxtoem(6);
    }

    &:last-child:after {
      content: normal;
    }
  }
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.list-flex {
  @extend %list-inline;
  display: flex;
  align-items: center;

  > li {
    flex: 1;
  }
}

hr {
  border: 1px dashed;
  opacity: .75;
  margin: pxtoem(15) 0;
  width: 100%;
  display: inline-block;

  &.bar {
    border: 0;
  }
}

figure {
  margin: 0;
}

.strong, .bold, strong, b {
  font-weight: 900;
}

.light {
  font-weight: lighter;
}

.no-break {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

ul {
  margin-bottom: 1em;
  padding-left: 20px;
  line-height: 1.2;

  > li {
    padding: 0.4em 0;
  }
}

small,
.small {
  @include font-size(-1);
}

.font-size-0 {
  @include font-size(0);
}

large,
.large,
.font-size-1 {
  @include font-size(1);
}

.font-size-2 {
  @include font-size(2);
}

.font-size-3 {
  @include font-size(3);
}

.font-size-4 {
  @include font-size(4);
}

.inline-spacer {
  display: inline-block;
  width: 0.5em;
}

.text-center {
  text-align: center;
}

.font-headline {
  font-family: $font-headline;
}

.font-body {
  font-family: $font-body;
}

.legalese {
  font-size: 12px;
}

.text-sideways {
  display: inline-block;
  left: 0;
  top: 0;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  margin: 0;
  @include h6;
  @include font-size(-1);
}


.landing h2,
.interior h2,
.services h2 {
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 1em;
  line-height: 1.5;
}

.landing h3,
.interior h3,
.services h3 {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.6;
}
