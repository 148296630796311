@import "../color/lib";
@import "../media/lib";

.timeline {
  ol {
    @extend .clearfix;
  }

  li {
    clear: both;
    border-left: 2px solid $light-gray;
    transform: translate(-1px, 0);

    .row {
      transform: translate(-1px, 0);
    }

    &:last-child {
      border: 0;
      transform: translate(0, 0);

      .row {
        transform: translate(0, 0);
      }
    }
  }

  &__item {
    text-align: left;

      img {
      max-height: 8em;
      margin-bottom:.5em;
    }
  }

  &__bar {
    position: relative;

    &:before, &:after {
      content: '';
      display: block;
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
      border: pxtoem(7) solid $blue;
      border-radius: 100%;
    }

    &:after {
      width: pxtoem(125);
      border-bottom: 2px solid $blue;
    }
  }

  &__content {
    margin-top: -.6em;
  }

  @media(max-width: $lg-max) {
    &__abstract {
      padding: pxtoem(35);
    }
  }

  @media(max-width: $sm-max) {
    .row {
      margin: 0 !important;
      padding-bottom: pxtoem(45);

      .col-auto {
        padding: 0;
      }
    }

    li:last-child {
      .row {
        padding-bottom: 0;
      }
    }

    &__bar {
      padding-right: 6px;

      &:after {
        width: 33px;
      }
    }

    &__abstract {
      padding: pxtoem(35) 0 pxtoem(35) pxtoem(35);
    }
  }

  @media(min-width: $sm) {
    li {
      width: 50%;
      float: right;

      &:nth-child(2n) {
        float: left;
        border-right: 2px solid $light-gray;
        border-left: 0;
        transform: translate(1px, 0);

        .timeline__item {
            text-align:right;

            img {
                margin-left:auto;
            }
        }

        .row {
          transform: translate(1px, 0);
          flex-direction: row-reverse;
          border-right: 0;
          text-align: right;
        }

        .timeline__bar:before {
          left: 100%;
        }
      }
    }
  }

  @media(min-width: $md) {
    .row {
      padding-bottom: pxtoem(60);
    }
  }
}

.timeline-month {
 font-size: .8em;
 font-weight: 400;
 font-style: italic;
}

.timeline__content {
    text-align:left;
    margin-bottom:1em;
}

.timeline__item {
  margin-bottom: 2em;
  font-size: 1em;
  line-height: 1.6em;

  img {
    max-height: 60px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}


