@import "../util/lib";
@import "../color/lib";

.bread {
    font-weight: 300;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 5px;

      a {
        color: black;
      }
    }

    li:last-of-type {
      font-weight: 700;
    }

    li:after {
      content: '/';
      padding-left: 5px;
    }

    li:last-of-type:after {
      content: none;
    }
}
