@import "../color/lib";
@import "../util/lib";
@import "../type/lib";

.btn {
  font-size: 0.85em;
  font-weight: 600;
  text-transform: uppercase;
  color: black;
  text-decoration: none !important;
  padding: 0.75em 1.25em;

  span {
    text-transform: uppercase;
    font-size: 0.85em;
    color: white;
    font-weight: 600;
  }

  &:hover {
    background-color: white !important;
    color: black;
  }
}

.btn-outline {
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
  text-decoration: none !important;

  &:hover {
    background-color: white !important;
    color: black;
  }
}

.btn.bg-blue:hover {
  background-color: $gray-2!important;
}
