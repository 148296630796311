@import "../color/lib";
@import "../type/lib";

#cookie-consent {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 999;
  background: $off-white;
  width: pxtoem(450);
  max-width: calc(100vw - 40px);
  box-shadow: 0 0 5px rgba($black, .1);
  display: none;
  font-size: 0.888em;

  &.active {
    display: block;
  }

  p {
    padding: pxtoem(30);
    margin: 0;
    border-bottom: 3px solid #fff;
  }

  a, button {
    width: 50%;
    float: left;
    text-align: center;
    cursor: pointer;
    margin: 0;
    border-radius: 0;
    font-size: 1em;

    /*@include font-size(-1);*/
    &:active, &:hover:focus {
      box-shadow: none;
      transform: none;
    }
  }
}
