@import "../type/lib";
@import "../color/lib";
@import "../util/lib";
@import "../layout/lib";

.leadership {
  .col-lg-4 {
    padding-bottom: 3em;

    .background-cover {
      height: 27.8em;
      clip-path: polygon(0 0,0 100%,100% 100%,100% 20%,87% 0);
      margin-bottom: 1em;
      background-position-y: top !important;

      @media(max-width:$md-max) {
        height: 35em;
      }

      @media(max-width:$xs-max) {
        height: 25em;
      }
    }

    h3 {
      font-size: 2em;
      font-weight: 600;
      margin-bottom: .2em;
    }

    .leader-title {
      font-style: italic;
      font-size: 1.25em;
      font-weight: 300;
      margin-bottom: .5em;
    }

    p {
      font-size: 1em;
      font-weight: 400;
      line-height: 1.6em;
    }

    &:hover {
      .background-cover {
        position: relative;
        cursor: pointer;

        &:before {
          opacity: .85;
          content: url(/Areas/CMS/assets/img/circle-arrow.png);
          text-align: center;
          padding-top: 10em;
          left: -50%;
          background-color: $dark-blue;
          height: 100%;
          width: 100%;
          position: absolute;
          top: -50%;
          transform: translate(50%, 50%);
        }

        @media(max-width:$md-max) {
          padding-top: 13em;
        }

        @media(max-width:$xs-max) {
          padding-top: 8em;
        }
      }
    }
  }
}

