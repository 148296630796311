@import "../type/lib";
@import "../color/lib";
@import "../util/lib";
@import "../layout/lib";

.footer-callout {
  position: relative;
  color: white;
  padding: 9em 0;

  .d-flex {
    justify-content: space-between;
    align-items: center;

    .callout-content {
      width: 50%;

      @media(max-width:$lg-max) {
        width: 65%;
      }
    }

    h3 {
      font-size: 3.5em;
      font-weight: 700;
      margin-bottom: .2em;
      line-height: 1.1;

      @media(max-width:$xs-max) {
        font-size: 3.3em;
      }
    }

    p {
      font-size: 1em;
      font-weight: 400;
      padding-bottom: 1.5em;
      line-height: 1.6em;
    }

    img {
      width: 122px;

      @media (max-width:$sm-max) {
        width: 80px;
      }
    }
  }

  &:after {
    content: "";
    position: absolute;
    background-color: #000;
    opacity: .8;
    height: 100%;
    width: 100%;
    top: 0;
  }

  .btn {
    span {
      color: black;
    }
  }
}

.content-callout {
  clip-path: polygon(0 0,0 100%,100% 100%,100% 45%,90% 0);

  @media(max-width:991px) {
    clip-path: polygon(0 0,0 100%,100% 100%,100% 25%,90% 0);
  }

  .col-md-6.text-white {
    height: 25.2em;
    position: relative;

    .text-div {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      padding: 0 5em;

      @media(max-width:$lg-max) and (min-width:$md) {
        padding: 0 3em;
      }

      @media(max-width: $sm-max) {
        padding: 2em !important;
      }

      h3 {
        font-size: 2em !important;
        font-weight: 600 !important;
      }

      padding {
        padding-bottom: 2em;
      }
    }

    &:hover {
      color: white;
    }
  }
}

.content-callout.safety {
  padding-bottom: 4em;

  .col-md-6.text-white {
    height: 30em;

    @media(max-width:991px) {
      height: 25em;
    }

    @media(max-width:767px) {
      height: auto;
      min-height: 20em
    }
  }

  &:nth-child(even) {
    .col-md-6.text-white {
      order: 2;
    }
  }
}

.content-callout.university {
  .col-md-6.text-white {
    background-color: #E8E8E8 !important;
    color: black !important;
  }

  .logo-div {
    text-align: center;
    padding-bottom: 1em;
    max-width: 100%;

    img {
      max-width: 170px;
    }
  }

  h3 {
    border-bottom: 1px solid #707070;
    font-size: 2em;
    font-weight: 700;
    padding-bottom: .6em;
  }
}

.logo-callout {
  background-color: #E8E8E8;
  text-align: center;

  &.vet-outreach {
    padding: 3em 0;

    .content-div {
      padding: 0em 3em 1em 3em;

      .partner-div {
        height: 13em;
        display: flex;
        align-items: center;

        img {
          padding: 0;
          max-height: 9em;
          max-width: 17em;
          margin: auto;
        }
      }
    }

    p {
      margin: 0;
      font-size: .75em;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  h4 {
    font-size: 1.2em;
    color: #263B85;
    font-weight: 500;
  }

  img {
    padding-top: 3em;
    padding-bottom: 3em;
    padding-left: 1em;
    padding-right: 1em;
    max-width: 100%;
  }

  > .d-sm-flex {
    justify-content: center;

    .content-div {
      padding: 0em 3em 3em 3em;

      .partner-div {
        height: 13em;
        display: flex;
        align-items: center;

        img {
          padding: 0;
          max-height: 9em;
          max-width: 17em;
          width: 100%;
          margin: auto;
        }
      }
    }

    p {
      margin: 0;
      font-size: .75em;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

  .benefits {
    h3 {
      font-size: 2em;
      font-weight: 600;
      margin-bottom: .5em;
    }

    .sub {
      font-size: 1.5em;
      font-weight: 400;
      line-height: 1.5;
      padding-bottom: .5em;
    }

    p {
      font-size: 1em;
      font-weight: 400;
    }

    h4 {
      color: $dark-blue;
      font-size: 1.5em;
      font-weight: 600;
      margin-top: 2em;
    }

    ul {
      column-count: 2;
      column-gap: 40px;

      li {
        break-inside: avoid;
        font-weight: 400;
      }
    }
  }

.honor {
  h3 {
    color: $dark-blue;
    border-bottom: 1px solid #707070;
    font-size: 2em;
    font-weight: 700;
    padding-bottom: .6em;
    margin-bottom: 0
  }

  p {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.6;
  }

  .row {
    padding-top: 2em;

    h4 {
      color: $dark-blue;
      font-size: 1.6em;
      font-weight: 600;
      margin-bottom: 1em;
    }

    .background-cover {
      height: 28em;

      @media(max-width:$md-max) {
        margin-top: 2em;
      }
    }

    p {
      padding-bottom: 1em;
    }

    .btn {
      background-color: transparent;
      border: 1px solid #32559C;
      color: $dark-blue;
    }
  }
}

  .pb-0 {
    padding-bottom: 0 !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

/*.c765 {
  .content-callout {
    .col-md-6.bg-black.text-white {
      background: $dark-blue!important;
    }
  }
}*/

.footer-callout_services {
  position: relative;
  color: white;
  padding: 3em 6.2em;

  @media(max-width:1279px) {
    padding-bottom: 1em;
  }

  @media(max-width:1279px) {
    padding-right: 3em;
    padding-left: 3em;
  }


  .container-fluid {
    position: relative;
    z-index: 2;
    padding: 0;

    > h3 {
      font-size: 1.75em;
      font-weight: 700;
      padding-bottom: 1em;
      margin-bottom: 0;
      color: #ffffff;
    }

    .row {
      margin: 0 -1em;

      div[class^="col-"] {
        padding: 0 1em;

        @media(max-width:1279px) {
          padding-bottom: 2em;
        }

        .services-callout {
          padding: 2em;
          position: relative;
          height: 350px;

          a {
            color: #ffffff;

            &:hover {
              text-decoration: none;
            }
          }

          .services-callout_inner {
            z-index: 2;
            position: relative;
            height: 100%;

            h3 {
              font-size: 1.5em;
              font-weight: 700;
              padding-bottom: .6em;
            }

            img {
              width: 3em;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }

          &:after {
            content: "";
            position: absolute;
            background-color: #000;
            opacity: .65;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            transition: all .3s;
          }

          &:hover:after {
            background-color: #263b85;
            opacity: .9;
          }
        }
      }
    }
  }

  &:after {
    content: "";
    position: absolute;
    background-color: #000;
    opacity: .8;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
