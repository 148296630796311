@import "../type/lib";
@import "../layout/lib";
@import "../color/lib";

#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px 0 rgba(102, 102, 102, 0.25);
  -webkit-transition: all .15s;
  transition: all .15s;
  background-color: #000000;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  

  @media(max-width:767px) {
    height: 60px;
  }

  .header_left {
    z-index: 2;
    padding-left: 6.2em;

    @media(max-width:767px) {
      padding-left: 26px;
    }

    .logo {
      width: 260px;
      display: block;

      @media(max-width:767px) {
        width: 180px;
      }

      &:before {
        content: "";
        display: block;
        background: url('/Areas/CMS/assets/img/QualTek_BlackBlue.png') no-repeat 50%;
        background-size: contain;
        width: 100%;
        padding-bottom: 18.4615%;
        -webkit-transition: all .15s;
        transition: all .15s;
      }
    }
  }

  .header_right {
    justify-content: flex-end;
    z-index: 2;
    padding-right: 6.2em;

    @media(max-width:767px) {
      padding-right: 26px;
    }

    #main-navigation {

      @media(max-width:1199px) {
        display: none;
      }

      ul.navigation {
        list-style: none;
        margin: 0;
        padding: 0;

        > li {
          display: inline-block;
          margin: 0 1.3em;
          position: relative;

          &:last-of-type {
              margin-right:0;
          }

          > a {
            color: #fff;
            font-weight: 700;
            font-size: .9em;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }

    .hamburger {
      width: 60px;
      height: 60px;
      margin-right: 0;
      margin-left: auto;
      padding: 20px 15px;
      background-color: transparent;
      cursor: pointer;
      display: none;
      position: relative;

      i {
        width: 100%;
        height: 3px;
        margin-bottom: 5px;
        display: block;
        background-color: #ffffff;
        border-radius: 3px;
        position: static;
      }

      @media(max-width:1199px) {
        display: block;
      }
    }
  }

  .header_angle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;


    &:after {
      content: "";
      position: absolute;
      right: 66%;
      top: 0;
      bottom: -1px;
      width: 100%;
      background-color: #ffffff;
      -webkit-transform: skew( 30deg );
      transform: skew( 30deg );
      -webkit-transition: all .15s;
      transition: all .15s;

      @media(max-width:1199px) {
        right: 50%;
      }

      @media(max-width:767px) {
        right: 30%;
      }
    }
  }
}

.home #header {
  background-color: transparent;
  box-shadow: none;
  background-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,.7), rgba(0,0,0,1));

  .header_left .logo:before {
    background-image: url('/Areas/CMS/assets/img/QualTek-Logo_White.png');
  }

  .header_angle:after {
    background-color: transparent;
  }
}

.home #header.scrolled {
  background-color: #000000;
  box-shadow: 0 4px 6px 0 rgba(102, 102, 102, 0.25);

  .header_left .logo:before {
    background-image: url('/Areas/CMS/assets/img/QualTek_BlackBlue.png');
  }

  .header_angle:after {
    background-color: #ffffff;
  }
}


.navigation .dropdown-menu {
  position: absolute;
  top: 100%;
  bottom: 0;
  left: -24px;
  z-index: 1000;
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity .5s linear;
  float: left;
  min-width: 160px;
  text-align: left;
  list-style: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;

  li {
    padding: 0;

    a {
      display: block;
      clear: both;
      font-weight: 400;
      line-height: 1.5;
      white-space: nowrap;
      padding: 8px 25px;
      background-color: #000;
      border-bottom: none;
      color: #fff;
      text-transform: none;
      font-size: 16px;

      &:hover {
        background-color:$dark-blue;
        text-decoration:none;
      }
    }
  }
}

ul.navigation li.dropdown-open .dropdown-menu {
  visibility: visible;
  opacity: 1;
}

#searchForm {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  text-align: right;
  visibility: hidden;
  opacity: 0;
  z-index: 1000;

  &.active {
    visibility: visible;
    opacity: 1;
    animation: dropdown 0.4s forwards;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    background: #333;
  }

  .searchbar-group {
    padding: 0 6.2em;
  }

  input {
    padding-top: 13px;
    padding-bottom: 11px;
    border: 0;
    background-color: transparent;
    color: #fff;
    outline: 0;
    font-size: 20px;
    flex: 1;
    text-align: right;
  }


  button {
    width: 60px;
    border: 0;
    background-color: transparent;
    color: #fff;
    height: 100%;
    outline: 0;
    transition: all .15s;
    float: right;
    padding: 13px 0;
    height: 100%;
    text-align: right;
  }
}

.search-toggle {
  background-color: transparent;
  color: #ffffff;
  border: 0;
  width:28px;

  &.active:before {
    content: "\f00d";
  }
}

@keyframes dropdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


#headerSearch::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}

#headerSearch::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}

#headerSearch:-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}

#headerSearch:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}

