@import "../type/lib";
@import "../color/lib";
@import "../util/lib";

.sitemap {
  @include font-size(2);
  color: $black;
  font-weight: 800;

  > ul > li > ul > li {
    @include font-size(-2);
    font-weight: 400;
  }

  ul {
    list-style: none;
    border-left: 1px solid #707070;
  }

  > ul {
    padding: 0;
    margin: 0;
    border: 0;
  }

  li {
    padding: 0;

    > a {
      margin: pxtoem(15) 0;
    }
  }

  a {
    display: table;
    color: $black;

    @include hover {
      color: $dark-blue;
      text-decoration: underline;
    }

    &:visited {
      color: $black;

      @include hover {
        color: $dark-blue;
        text-decoration: underline;
      }
    }
  }
}
