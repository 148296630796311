@import "../util/lib";
@import "../media/lib";

$animation-delay: 200ms;

.animate {
  &--fade-up {
    transition: transform 1.6s cubic-bezier(0.21, 0.32, 0.18, 0.92), opacity 1s;
    transform: translate(0, 4rem);
    opacity: 0;

    &.active {
      transform: translate(0, 0);
      opacity: 1;
    }

    @include loading {
      transform: translate(0, 4rem);
      opacity: 0;
    }
  }

  &--fade-down {
    transition: transform 1.6s cubic-bezier(0.21, 0.32, 0.18, 0.92), opacity 1s;
    transform: translate(0, -4rem);
    opacity: 0;

    &.active {
      transform: translate(0, 0);
      opacity: 1;
    }

    @include loading {
      transform: translate(0, -4rem);
      opacity: 0;
    }
  }

  &--fade-in {
    transition: opacity 1s;
    opacity: 0;

    &.active {
      opacity: 1;
    }

    @include loading {
      opacity: 0;
    }
  }

  &--delay {
    @for $i from 1 through 7 {
      &-#{$i} {
        transition-delay: $animation-delay * $i !important;
      }
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


.content-block {
  .content-block__content {
    transform: translateX(-2.5rem);
    opacity: 0;
    transition: transform 1.6s cubic-bezier(0.21, 0.32, 0.18, 0.92), opacity 1.4s; 
  }

  &.active {
    .content-block__content {
      transform: translateX(0);
      opacity: 1;
    }

    .bar--animate {
      @extend .bar--animate.active;
    }
  }

  @include loading {
    .content-block__content {
      transform: translateX(-2.5rem);
      opacity: 0;
      transition: transform 1.6s cubic-bezier(0.21, 0.32, 0.18, 0.92), opacity 1.4s;
    }

    .bar--animate {
      &:before {
        transform: scaleX(0);
      }

      .text {
        transform: translateX(-1.5rem);
        opacity: 0;
      }
    }
  }
}


.content-block-centered {
  position: relative;
  text-align: center;
  padding-top: pxtorem(120) + 1.5rem;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border-right: 1px solid;
    height: pxtorem(120);
    display: block;
    opacity: .4;
  }

  @media (min-width: $sm) {
    padding-top: pxtorem(170) + 1.5rem;

    &:before {
      height: pxtorem(170);
    }
  }

  &.animate {
    &:before {
      transform: scaleY(0);
      transform-origin: 0 0;
      transition: transform 1.6s cubic-bezier(.45,.23,.27,1);
    }

    .content-block-centered__top, .content-block-centered__content {
      transition: transform 1.6s cubic-bezier(.21,.32,.18,.92), opacity 1s;
      transform: translateY(-4rem);
      opacity: 0;
    }
  }

  .content-block-centered__content {
    @extend .animate--delay-1;
  }

  &.active {
    &:before {
      transform: scaleY(1);
    }

    .content-block-centered__top, .content-block-centered__content {
      transform: translateY(0);
      opacity: 1;
    }

    .content-block-centered__content {
      @extend .animate--fade-down.active;
    }
  }
}
