@import "../color/lib";
@import "../layout/lib";
@import "../util/lib";

@mixin nav-active {
  @at-root {
    .mobile-nav-open #{&} {
      @content;
    }
  }
}

@mixin nav-visible {
  @at-root {
    .mobile-nav-expanded #{&} {
      @content;
    }
  }
}

$side-bar-animation-timing: 500ms;

#side-bar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  color: $white;
  z-index: 500;

  .toggle-mobile-menu {
    display: block;
    margin: 23px auto 0;
    position: relative;
    z-index: 55;
    width: 65%;
    max-width: pxtoem(35);
    color: $white;

    @media (min-width: $xs) {
      margin: 25px auto 0;
    }

    @media (min-width: $sm) {
      margin: 32.5% auto 0;
    }
  }

  &__label {
    span {
      display: block;
      color: $medium-gray;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 50;
      margin: auto 0;
      text-align: center;
      opacity: 0;
      transition: opacity .4s;
      height: 80%;

      &.active {
        opacity: 1;
      }
    }
  }

  &__side {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow: hidden;
    background: $black;
  }

  #big-balloon {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    text-align: center;
    padding: 35% 0;
    color: $white;
    transform: translate(0, 50%);
    transition: transform .6s, opacity .6s;
    opacity: 0;

    &.active {
      transform: translate(0, 0);
      opacity: 1;
      text-decoration: none;

      @include hover {
        opacity: .5;
        transition: all .15s;
      }
    }

    &:before {
      @include icon(long-arrow-alt-up);
    }
  }
}

#side-bar {
  width: 39px;
}

#site {
  padding-right: 39px;
}

@media(min-width: $sm) {
  #site {
    padding-right: $side-bar-width * .5;
  }

  #side-bar {
    width: $side-bar-width * .5;
  }
}

@media(min-width: $md) {
  #site {
    padding-right: $side-bar-width * .75;
  }

  #side-bar {
    width: $side-bar-width * .75;
  }
}

@media(min-width: $xl) {
  #site {
    padding-right: $side-bar-width;
  }

  #side-bar {
    width: $side-bar-width;
  }
}

#flyout-nav {
  position: absolute;
  right: 100%;
  top: 0;
  background: $black;
  height: 100%;
  transform: translate(100%, 0);
  transition: transform $side-bar-animation-timing;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: -1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: $dark-gray;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: $gray;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    transition: opacity $side-bar-animation-timing;
    min-height: 100%;
    align-items: center;
  }

  @include nav-active {
    transform: translate(0, 0);
    transition: transform $side-bar-animation-timing;

    #flyout-nav__content {
      opacity: 1;
      transition: opacity $side-bar-animation-timing;
    }
  }

  .logo {
    width: pxtoem(20);
  }

  .main-nav {
    width: 100%;

    li {
      padding: 0;
    }

    > ul {
      padding: 20px 0;

      @media (min-width: $sm) {
        padding: $block / 2 0;
      }
    }

    a {
      color: $white;
      transition: background .15s;
      display: block;
      background: rgba($gray, 0);
      min-width: pxtoem(260);
      width: 100%;
      padding: pxtovh(20) 25px;

      @media (min-width: $xs) {
        padding: pxtovh(20) $block / 2;
        min-width: pxtoem(370);
      }

      @media(min-width: $lg) {
        padding: pxtovh(20) pxtoem(20) pxtovh(20) $block;
        min-width: pxtoem(465);
      }

      .h3 {
        display: block;
        margin-bottom: 0.25em;
      }

      p {
        margin-bottom: 0;
        line-height: 1.4;
      }

      @include hover {
        background: rgba($gray, .5);
        text-decoration: none;
      }
    }

    .home-link {
      padding-bottom: pxtovh(30);

      @media (min-width: $sm) {
        padding-bottom: pxtovh(50);
      }

      a {
        min-width: 0;
        width: auto;
        display: table;
        transition: opacity .15s;

        @include hover {
          background: transparent;
          opacity: .6;
        }
      }

      .logo--m {
        width: pxtoem(30);
      }

      .logo--white {
        width: pxtoem(100);
      }
    }
  }

  .text-scroller__container {
    display: none;

    @media (min-width: $xs) {
      display: inline-block;
    }
  }
}
