@import "../type/lib";
@import "../color/lib";
@import "../util/lib";


.news-listing {
  h2 {
    font-weight: 600;
    font-size: 1.5em;
    border-bottom: 1px #707070 solid;
    padding-bottom: .5em;
  }

  .d-flex {
    padding-bottom: 3em;

    .news-img {
      width: 37em;
      height: 12em;
      clip-path: polygon(0 0,0 100%,100% 100%,100% 35%,80% 0);

      @media(max-width:$lg-max) {
        height: 9em;
      }

      @media(max-width:$md-max) {
        height: 9em;
      }

      @media(max-width:$xs-max) {
        height: 6em;
      }
    }

    .news-content {
      padding-left: 2em;
      width: 115em;

      &.no-image {
        padding-left: 0;
      }

      .meta {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.7em;
      }

      .news-date {
        font-size: .7em;
        font-weight: 300;
        padding-bottom: .5em;
      }

      .news-title {
        font-size: 1.25em;
        text-decoration: underline;
        color: $dark-blue;
        font-weight: 500;
        line-height: 1.5;
      }

      p {
        font-size: 1em;
        padding-top: .5em;
        line-height: 1.6;
        font-weight: 400;
      }
    }
  }
}

.quotation {
  .vert-line {
    flex: 0 0 0.4em;
    background: $blue;
    margin-right: 2em;
  }

  .quote {
    font-size: 1.25em;
    color: $dark-blue;
    line-height: 1.3;
  }

  .source {
    padding-top: .5em;
    font-size: .85em;

    .source-title {
      font-style: italic;
      color: black;
    }
  }
}

.news-interior h1 {
    font-size:1.5em;
    margin-top:0;
    margin-bottom:1em;
}

.news-pagination {
  background-color: #E8E8E8;
  width: 100%;
  justify-content: space-between;
  padding: 2em 0 !important;
  align-items: center;

  .results {
    order: 2;
    margin: 0;
    padding-right: 30px;
  }


  ul {
    list-style-type: none !important;
    margin: 0 !important;

    li {
      display: inline !important;
      list-style-type: none !important;
      padding: .4em 0.5em !important;

      a {
        color: black;
        font-weight: 300;
      }

      &.active {
        a {
          font-weight: 600;
        }
      }
    }
  }
}


.article-content {
    img {
        max-width:100%;
        display:block;
        height:auto;
        margin-bottom:1em;
    }
}


.search-bar {
  position: relative;
  padding-bottom: 3em;

  input {
    padding-right: 60px;
  }

  button {
    width: 60px;
    height: 45px;
    border: 0;
    background-color: transparent;
    color: #000;
    outline: 0;
    transition: all .15s;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin:1em 0 3em 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.article-img {
  @media(max-width:$md-max) {
    margin-top: 2em;
  }
}
