@import "../type/lib";
@import "../color/lib";
@import "../util/lib";
@import "../layout/lib";


.contact-banner {
  h3 {
    font-size: 1.25em;
  }

  .contact-content {
    padding-left: 5em;

    h2 {
      padding-bottom: 2em;
    }
  }

  .col-md-4 {
    .inline {
      div {
        font-size: .9em;
        font-weight: 300;
      }
    }
  }
}
