@import "../type/lib";
@import "../color/lib";
@import "../util/lib";
@import "../media/lib";
@import "../layout/lib";

.gallery {
  position: relative;

  h3 {
    border-bottom: 1px solid #707070;
    font-size: 2em;
    font-weight: 700;
    padding-bottom: .6em;
  }

  .slider--gallery {
    padding-bottom: 2em;
    margin-bottom: 5em;

    .slick-track {
      margin-left: -1em;
    }

    .slider-test {
      div {
        height: 23em;
        margin: 1em;
        @media(max-width:$sm-max) {
          margin-right:0;
        }
      }
    }

    .slick-arrow {
      position: absolute;
      bottom: 0;
      border: solid 1px black;
      padding: 0.2em 0.8em;
      background: white;
    }

    .slick-prev {
      left: 0;

      &:after {
        content: url(/areas/cms/assets/img/slider-left.png);
        position: relative;
        top: 0.2em;
      }
    }

    .slick-next {
      left: 2.5em;

      &:after {
        content: url(/areas/cms/assets/img/slider-right.png);
        position: relative;
        top: 0.2em;
      }
    }
  }
}

.vet {
  position: relative;
  z-index:100;

  h3 {
    border-bottom: 1px solid #707070;
    font-size: 2em;
    font-weight: 700;
    padding-bottom: .6em;
    margin-bottom: 0
  }

  .slider--veteran {
    padding-bottom: 2em;

    .slick-track {
      margin-left: -2em;

      .slick-slide {
        margin: 1em;
      }
    }

    .slick-arrow {
      position: absolute;
      bottom: 0;
      border: solid 1px black;
      padding: 0.2em 0.8em;
      background: white;
    }

    .slick-prev {
      left: 0;
      z-index: 1000;

      &:after {
        content: url(/areas/cms/assets/img/slider-left.png);
        position: relative;
        top: 0.2em;
      }
    }

    .slick-next {
      left: 2.5em;
      z-index: 1000;

      &:after {
        content: url(/areas/cms/assets/img/slider-right.png);
        position: relative;
        top: 0.2em;
      }
    }
  }

  .vet-slide {
    padding: 2em;
    background: #E8E8E8;
    margin: 1em;

    .vet-img {
      width: 100%;
      height: 13em;
      margin-right: 1.5em;
      margin-bottom: 1.5em;
      background-position-y:top!important;

      @media(max-width:$md-max) {
        height: 10em;
      }
    }

    .info {
      width: 30em;
      font-size: 1.2em;

      .name {
        color: $dark-blue;
        font-weight: 600;
      }

      .vet-title {
        font-style: italic;
      }
    }

    .modal-text {
      font-weight: 300;
      height: 10em;
      overflow: hidden;
      margin-bottom: 1.5em;
    }

    a {
      font-size: .8em;
      font-weight: 300;
      color: $dark-blue;
      text-decoration: underline;
    }
  }
}


.slider--outreach {
  .slick-arrow {
    position: absolute;
    top: 50%;
    border: 0;
    padding: 0.2em 0.8em;
    background: transparent;
  }

  .slick-prev {
    left: 0;
    z-index: 1000;

    &:after {
      content: url(/areas/cms/assets/img/slider-left.png);
      position: relative;
      top: 0.2em;
    }
  }

  .slick-next {
    right: 0em;
    z-index: 1000;

    &:after {
      content: url(/areas/cms/assets/img/slider-right.png);
      position: relative;
      top: 0.2em;
    }
  }
}

.banner-slider {
  position: relative;

  .slider--banner {
    position: relative;
    top: 0;

    .slider-part {
      position: relative;
      height: 22em;

      @media(max-width:$xs-max) {
        height: 30em;
      }

      .content {
        position: absolute;
        top: 45%;
        transform: translate(15%,-50%);

        .container {
          padding: 0;
        }

        h3 {
          font-size: 2em;
          font-weight: 600;
          width: 65%;
          line-height: 1.2;

          @media(max-width: $sm-max) {
            width: 70%
          }
        }

        p {
          font-size: 1em;
          font-weight: 400;
          width: 65%;
          line-height: 1.6;

          @media(max-width: $sm-max) {
            width: 70%
          }
        }
      }

      &:after {
        content: "";
        position: absolute;
        background-color: #000;
        opacity: .8;
        height: 100%;
        width: 100%;
        top: 0;
      }
    }

    .slick-prev {
      position: absolute;
      bottom: 16%;
      left: 11em;
      z-index: 1000;
      background-color: transparent;
      border: none;

      &:after {
        content: url(/areas/cms/assets/img/circle-left.png);
        position: relative;
        top: 0.2em;
      }

      @media(max-width:$xl-max) {
        left: 10em;
      }

      @media(max-width:$lg-max) {
        bottom: 13%;
        left: 7.8em;
      }

      @media(max-width:$md-max) {
        bottom: 7%;
        left: 5.8em;
      }

      @media(max-width:$sm-max) {
        bottom: 7%;
        left: 6em;
      }

      @media(max-width:$xs-max) {
        bottom: 3%;
        left: 3.5em;
      }
    }

    .slick-next {
      z-index: 1000;
      bottom: 16%;
      position: absolute;
      left: 14.5em;
      background-color: transparent;
      border: none;

      &:after {
        content: url(/Areas/CMS/assets/img/circle-right.png);
        position: relative;
        top: 0.2em;
      }

      @media(max-width:$xl-max) {
        left: 15em;
      }

      @media(max-width:$lg-max) {
        bottom: 13%;
        left: 12.8em;
      }

      @media(max-width:$md-max) {
        bottom: 7%;
        left: 10.8em;
      }

      @media(max-width:$sm-max) {
        bottom: 7%;
        left: 11em;
      }

      @media(max-width:$xs-max) {
        bottom: 3%;
        left: 8.5em;
      }
    }
  }

  > p {
    font-size: 1.2em;
    font-weight: 300;
  }
}
