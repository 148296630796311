@import "../media/lib";

.d {
  @include breakpoints {
    &-block {
      display: block !important;
    }

    &-flex {
      display: flex !important;
    }

    &-inline {
      display: inline !important;
    }

    &-inline-block {
      display: inline-block !important;
    }

    &-none {
      display: none !important;
    }
  }
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

article.banner-top {
  padding-bottom:1.5em;
}
