@import "../type/lib";
@import "../color/lib";
@import "../util/lib";
@import "../layout/lib";



.tab-content {

  > div {
    z-index: 0;
    opacity: 0;
    transition: opacity .6s;
    position: absolute;
    top: 0;
    pointer-events: none;
    width: 100%;

    ul {
      column-count: 2;
      column-gap: 40px;

      li {
        break-inside: avoid;
      }

      @media(max-width:$sm-max) {
        column-count: 1;
      }
    }

    &.active {
      z-index: 10;
      opacity: 1;
      position: relative;
      pointer-events: all;
    }
  }

  h4 {
    font-size: 1.2em;
  }
}

.home-tabs {

  > .row {
    padding: 0.5em 0;

    p {
      font-size: 1em;
      font-weight: 400;
      line-height: 1.6;
    }
  }

  .row:last-child {
    padding-top: 4em;

    .tabs {
      div {
        padding: .5em 0;

        .tab-img {
          width: 1.8em;
          height: 2.15em;
          padding: 0;
          position: relative;
          cursor: pointer;

          img {
            max-height: 1.3em;
            position: absolute;
            max-width: 1.2em;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            filter: invert(1);
          }
        }

        .tab-title {
          font-size: .8em;
          font-weight: 400;
          padding: .5em;
          text-transform: uppercase;
          color: white;
          cursor: pointer;
        }

        &.active {
          .tab-title {
            background-color: #5594FF;
            color: black;
            padding-right: 0.75em;
          }

          .tab-img {
            background-color: #5594FF;

            img {
              filter: unset;
            }
          }
        }
      }

      @media(max-width: $md-max) {
        padding-bottom: 2em;
      }
    }

    .tab-content {
      a {
        color: white;
        text-transform: uppercase;
        text-decoration: underline;
        font-size: .85em;
        font-weight: 600;

        &:hover {
          color: $blue;
        }
      }
    }
  }

  .btn {
    background-color: transparent !important;
    text-decoration: none;

    &:hover {
      background-color: white !important;

      span {
        color: black;
        text-decoration: none;
      }
    }
  }



  @media(max-width: $md-max) {
    padding: 2em !important;
  }
}

.home-tabs .row:last-child .tabs div:hover .tab-img img,
.home-tabs .row:last-child .tabs div:hover .tab-title {
  opacity: 0.75;
}

@media (min-width:1280px) {
  .home-tabs > .row p {
    max-width: 65%;
  }
}

.service-tabs {

  > .row {
    padding-top: 1em;
  }

  h3 {
    margin: 0;
    padding-bottom: .6em;
    font-size: 2em;
    font-weight: 600;
    border-bottom: 1px solid #707070;
  }

  .tabs {

    div {
      font-weight: 400;
      font-size: 1.25em;
      padding: 0.5em 0 0.5em 0.75em;
      color: black;
      line-height: 1.3;
      cursor: pointer;

      &.active {
        background-color: #263B85;
        font-weight: 600;
        color: white;
        text-decoration: none !important;

        &:hover {
          text-decoration: underline;
          color:white!important;
        }
      }

      &:hover {
        color: #263b85;
      }
    }

    .tab-content {
      font-weight: 300;
    }

    @media(max-width:$md-max) {
      margin-bottom: 2em;
    }
  }
}
