@import "../media/lib";
@import "../color/lib";
@import "../type/lib";
@import "../util/lib";
@import "../layout/lib";

.tiles {
  flex-flow: row wrap;
  justify-content: space-between;

  .tile {
    width: 44%;
    padding-bottom: 2.5em;

    h3 {
      font-size: 2em;
      font-weight: 700;
      border-bottom: 1px #707070 solid;
      padding-bottom: 0.3em;
      color: $black;
    }

    div {
      height: 20em;
      margin-bottom: 1.5em;
      clip-path: polygon(0 0,0 100%,100% 100%,100% 45%,83% 0);
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: $dark-blue;
        opacity: 0;
        transition: opacity ease-in-out .3s;
      }
    }

    &--service {
      div {
        &:after {
          content: "";
          position: absolute;
          clip-path: polygon(0 0,0 100%,100% 100%,100% 100%,0 0);
          background-color: #263b85;
          height: 8em;
          width: 5em;
          top: 11.95em;
          left: 0;
        }

        img {
          position: absolute;
          bottom: 1em;
          z-index: 5;
          left: 1em;
          max-width: 1.7em;
          max-height: 1.9em;
        }
      }
    }

    p {
      font-size: 1em;
      line-height: 1.6;
      color: #000;
      font-weight: 400;
    }

    &:hover {
      .background-cover {
        &:before {
          opacity: .85;
          content: url(/Areas/CMS/assets/img/circle-arrow.png);
          text-align: center;
          padding-top: 7em;

          @media(max-width:$xl-max) {
            padding-top: 6em;
          }
        }
      }
    }
  }
}

.tiles .tile a:hover {
  text-decoration: none;
}

.safety-tiles {
  padding-bottom: 2em;
  flex-flow: row wrap;
  justify-content: space-between;

  .background-cover {
    position: relative;
    height:30em;
    margin-bottom: 2em;
    width: 48.6%;
    padding-top: 3.2em;
    padding-left: 3em;
    padding-right: 3em;
    z-index:3;

    &:after {
      content: '';
      position: absolute;
      background-color: black;
      opacity: .7;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    >div {
      position: relative;
      z-index: 2;

      h2 {
        font-size: 2.1em;
        font-weight: 600;
        margin-bottom: .5em;
      }

      p {
        font-size: .9em;
        font-weight: 300;
      }
    }
  }
}

@media(max-width:$md-max) {
  .tiles {
    .tile {
      width: 100%;
    }
  }

  .safety-tiles {
    .background-cover {
      width: 100%;
    }
  }
}

.c765 .tiles .tile--service div:after,
.c780 .tiles .tile--service div:after {
  content: none;
}
