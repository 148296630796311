@import "../color/lib";
@import "../util/lib";

[off-canvas] {
  background-color: #000000;
  z-index: 99;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  li {
    width: 100%;
    outline: none;
    border: none;
    padding: 0;

    a {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0;
      width: 100%;
      padding: 18px 24px;
      display: block;
      color: #ffffff;
      outline: none;
      border: none;
      text-decoration: none;
      background-color: transparent;

      &:hover,
      &:active {
        background-color: #999999;
      }
    }
  }
}

.sb-slidebar li {
  position: relative;
  margin-bottom: 0
}

.sb-slidebar li a {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  width: 100%;
  padding: 15px 24px 17px;
  display: block;
  color: #E2E8EF
}

.sb-slidebar .navbar-collapse {
  padding: 0
}

.sb-slidebar .navbar-collapse .navbar-nav {
  margin: 0 0 100px 0
}

.sb-slidebar .navbar-collapse li {
  border: none
}

.sb-slidebar .navbar-collapse li {
  width: 100%
}

.sb-slidebar .navbar-collapse .nav > li > a:focus,
.sb-slidebar .navbar-collapse .nav > li > a:hover {
  background-color: #333;
  color: #fff
}

.sb-slidebar .navbar-collapse .nav > li > .toggle-submenu {
  cursor: pointer;
  height: 53px;
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
  background-color: transparent
}

.sb-slidebar .navbar-collapse .nav > li > .toggle-submenu:after {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #fff;
  pointer-events: none;
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -3px;
  margin-left: -5px
}

.sb-slidebar {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  background-color: #000
}

.sb-slidebar.custom-width {
  width: 40%
}

@media(max-width:767px) {
  .sb-slidebar.custom-width {
    width: 50%
  }
}

@media(max-width:539px) {
  .sb-slidebar.custom-width {
    width: 80%
  }
}

.sb-submenu {
  background-color: #333
}

.sb-submenu > li > a,
.sb-slidebar li a.active {
  background-color: #333
}

.sb-slidebar li a.active {
  color: #fff
}

.sb-submenu > li > a:hover,
.sb-submenu > li > a:focus {
  color: #fff;
  background-color: #243155
}

.sb-submenu {
  display: none;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background-color: #000;
  font-style: normal;
  clear: both;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400
}

.sb-submenu li {
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative
}

.sb-submenu li a {
  color: #c4c4c4;
  text-transform: none;
  position: relative;
  padding: 11px 24px 13px;
  font-size: 16px;
  font-weight: 400
}

.sb-submenu li a:hover,
.sb-submenu li a:focus {
  color: #fff;
  background-color: #000
}

.sb-submenu li:first-child a {
  padding-top: 22px
}

.sb-submenu li:last-child a {
  padding-bottom: 26px
}


.search-block {
  position: relative;

  input {
    padding-right: 60px;
    border: 0;
    background-color: transparent;
    color: #fff;
    padding-left: 24px;

    &:focus {
        border:0;
        outline:0;
    }
  }

  button {
    width: 60px;
    height: 45px;
    border: 0;
    background-color: transparent;
    color: #fff;
    outline: 0;
    -webkit-transition: all .15s;
    transition: all .15s;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
}


#site-search::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}

#site-search::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}

#site-search:-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}

#site-search:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}
